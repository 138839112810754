const Footer = () => {
    const socialData = [
        {
            imgUrl: "social-1",
            link: 'https://t.me/telegram'
        },
        {
            imgUrl: "social-4",
            link: 'https://uniswap.com'
        },
        {
            imgUrl: "social-5",
            link: 'https://coinmarketcap.com'
        },
        {
            imgUrl: "social-2",
            link: 'https://coingecko.com'
        },
        {
            imgUrl: "social-3",
            link: 'https://twitter.com'
        },

    ]
    return (
       
       <div className="w-full text-[18px] bg-gradient-to-l from-[#1A1F4C] to-[#41739A]    text-[#fff] p-5 mt-5">
    All Rights Reserved, $DOGELON
    </div>
    );
}

export default Footer;