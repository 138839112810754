import { motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
import { AiFillInstagram, AiFillYoutube, AiOutlineArrowRight, AiOutlineTwitter } from 'react-icons/ai';
import Button from '../utils/Button';
import { SiTelegram } from 'react-icons/si';

const Header = () => {

    return (<motion.div className="  bg-gradient-to-l from-[#1A1F4C] to-[#41739A]   w-full text-[#fff] py-5   text-[24px] font-semibold flex flex-col  sm:flex-row z-20 gap-5 items-center  justify-between md:justify-between px-2 md:px-10"
        variants={slideIn('up', 'spring', 0.1, 0.4)}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}

    >
        <div className=' flex flex-row gap-3 items-center justify-start rounded-full'>
       
            <h1>$DOGELON</h1>
        </div>
        <motion.div className=' flex flex-row gap-10 items-center  justify-center'
        >
            <a href="https://t.me/DogelonOnSolana" className='hover:no-underline'>

               <Button text="Telegram" classProps="bg-[#41739A] text-[#fff] font-bold border-[#fff]" scale="out" />
            </a>
        </motion.div>

    </motion.div>);
}

export default Header;