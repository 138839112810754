import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
import { FaTelegram } from "react-icons/fa";
import { AiFillTwitterCircle, AiOutlineCopy } from "react-icons/ai"
import MarqueeImage from "../utils/MarqueeImage";
import CA from "../utils/Ca";
import Button from "../utils/Button";
import AOS from 'aos';
import 'aos/dist/aos.css'
import AnimeLogo from "../utils/AnimeLogo";

const About = () => {



  AOS.init({ once: true });



  const [canPlay, setCanPlay] = useState(false);

  return (<div className="w-full bg-[] pt-5 text-[#1e1e1e] relative  text-center mt-5 ">
    <div className="px-4 md:px-10 flex flex-col gap-2 items-center justify-center">
      <h1 className="text-[30px] md:text-[45px] font-bold">About<br /> </h1>             <h1 className="text-[25px] sm:text-[45px] md:text-[75px] leading-tight  "><span className="font-bold">Token Name: </span>DOGELON</h1>
      <h1 className=" leading-tight text-[25px] sm:text-[45px] md:text-[75px]"><span className="font-bold">Ticker:</span> $DOGELON</h1>
      <h1 className=" leading-tight text-[14px]md:text-[30px] uppercase ">A1EuYiZMaPVvTX22zfGrqCXQuBKBR6Zph9eHaHK8ZHpj</h1>
      <div className="flex flex-row w-full items-center justify-center gap-3 underline">
      <a href="https://x.com/Dogelonsols" className='hover:no-underline'>

        <Button text="X" classProps="bg-[#1A1F4C] text-[#fff] font-bold border-[#41739A]" scale="out" />
      </a>  <br />
         <a href="https://t.me/DogelonOnSolana" className='hover:no-underline'>

        <Button text="Telegram" classProps="bg-[#1A1F4C] text-[#fff] font-bold border-[#41739A]" scale="out" />
      </a>
      <a href="https://dexscreener.com/solana/6Ksf4j44CuJk55n6rvTvhoVd86rYEMkreGb3NF9xKUZh" className='hover:no-underline'>

        <Button text="Dexscreener" classProps="bg-[#1A1F4C] text-[#fff] font-bold border-[#41739A]" scale="out" />
      </a> 
    </div>
      <div className="mx-auto">
        <AnimeLogo imgUrl="logo.png" classProps={`rounded-full`} />
      </div>
    </div>

  
   
   
  

  </div>);
}

export default About;