import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { loaderVariants, slideIn } from "../utils/motion";
import { FaArrowRight, FaTelegram, FaTwitter } from "react-icons/fa";

import { AiFillTwitterCircle, AiOutlineCopy, AiOutlineArrowRight, AiOutlineTwitter, AiOutlineRight, AiOutlineWallet } from "react-icons/ai"
import Button from "../utils/Button";
import AnimeLogo from "../utils/AnimeLogo";
import CA from "../utils/Ca";
import AOS from 'aos';
import 'aos/dist/aos.css'

const Hero = ({ pryColor
    , secColor }
) => {



    AOS.init({ once: true });
    const [isCopied, setIsCopied] = useState(false);
    const handleCopied = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000)
    }

    const contractRef = useRef();

    const copyContractText = () => {
        const text = contractRef.current.textContent;
        navigator.clipboard.writeText(text);
        console.log('Text copied: ' + text);
        handleCopied();
    };


    return <div className=" text-[#000] md:h-screen md:justify-center md:mt-5 items-center  text-center  w-[100%] flex flex-col gap-2 ">

        <div className="w-[80%]  mx-auto flex flex-col-reverse  md:flex-row-reverse justify-center items-center">

          
            <div className="w-full flex  flex-col gap-5 md:w-[60%]">
          
            <h1 className="relative font-black z-10   text-left leading-tight text-[85px] md:text-[100px] text-stroke-[#41739A] stroke-text text-[#1A1F4C] ">$DOGELON</h1>
            <p className="text-[25px] text-left  text-[#000]">
            I am Dogelon. Dogelon Mars. Join me and together we will reach the stars.
            </p>
            <div className="flex flex-row w-full gap-5 ">
                <a href="https://x.com/Dogelonsols"><img src="../img/twitter2.webp" alt="twitter" className="w-[35px]" /></a>
                <a href="https://t.me/DogelonOnSolana"><img src="../img/tg.webp" alt="twitter" className="w-[35px]" /></a>
                <a href="https://dexscreener.com/solana/6Ksf4j44CuJk55n6rvTvhoVd86rYEMkreGb3NF9xKUZh"><img src="../img/dextools.webp" alt="twitter" className="w-[35px]" /></a>
              
            </div>
            <div className="flex flex-row gap-5">
                <a href="https://raydium.io/swap?outputCurrency=etg47tBNbrh74atYUcCMrrbkHcrHj9Bm9cpXhCSJWGt"><button className='rounded-lg bg-[#41739A] text-[#fff] px-3 py-2 font-black transition   duration-300 hover:scale-110'>buy now</button></a>
                <a href="https://dexscreener.com/solana/6Ksf4j44CuJk55n6rvTvhoVd86rYEMkreGb3NF9xKUZh"><button className='rounded-lg border-[#41739A] border-2 text-[#1A1F4C] px-3 py-2 font-black  transition   duration-300 hover:scale-110'>see chart</button></a>
            </div>
        </div>
        <div className="w-full hidden md:flex  flex-row justify-start md:w-[40%] my-5 md:my-0">

            <AnimeLogo imgUrl={`../img/logo-1.jpg`} />
        </div>
        <div className="w-full md:hidden flex flex-row items-center justify-center  my-5 md:my-0">

            <AnimeLogo imgUrl={`../img/logo.png`} />
        </div>
      

        </div>


    </div>;
}

export default Hero;